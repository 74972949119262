






























import {
  Component, PropSync, Vue,
} from 'vue-property-decorator';
import AllianzDepositViewModel
  from '@/vue-app/view-models/allianz-dashboard/deposits/allianz-deposit-view-model';

@Component({
  name: 'AllianzDeposit',
  components: {
    RecurringDepositDialog: () => import('@/vue-app/components/allianz-dashboard/deposits/recurring_deposits/RecurringDepositDialog.vue'),
    FlexibleDepositDialog: () => import('@/vue-app/components/allianz-dashboard/deposits/flexible_deposits/FlexibleDepositDialog.vue'),
    DepositOptions: () => import('@/vue-app/components/allianz-dashboard/deposits/DepositOptions.vue'),
    DepositOption: () => import('@/vue-app/components/allianz-dashboard/deposits/DepositOption.vue'),
    RecurringDeposit: () => import('@/vue-app/components/allianz-dashboard/deposits/recurring_deposits/RecurringDeposit.vue'),
  },
})
export default class AllianzDeposit extends Vue {
  @PropSync('isOpen', { type: Boolean, required: true })
  synced_is_open!: boolean;

  allianz_deposit_view_model = Vue.observable(
    new AllianzDepositViewModel(),
  )

  endProcess() {
    this.synced_is_open = false;
  }

  async scheduleCall() {
    this.$emit('showCalendlyModal');
    this.endProcess();
  }
}


































import { Component, Vue } from 'vue-property-decorator';
import OptionsCard from '@/vue-app/components/allianz-dashboard/OptionsCard.vue';
import OperationsViewModel from '@/vue-app/view-models/allianz-dashboard/operations-view-model';
import AllianzWithdrawal
  from '@/vue-app/components/allianz-dashboard/withdrawals/AllianzWithdrawal.vue';
import AllianzDeposit from '@/vue-app/components/allianz-dashboard/deposits/AllianzDeposit.vue';
import CalendlyModal
  from '@/vue-app/components/allianz-dashboard/deposits/special_deposits/CalendlyModal.vue';

@Component({
  name: 'Operations',
  components: {
    AllianzDeposit, AllianzWithdrawal, OptionsCard, CalendlyModal,
  },
})
export default class Operations extends Vue {
  operations_view_model = Vue.observable(new OperationsViewModel());
}

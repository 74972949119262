import i18n from '@/vue-app/plugins/i18n';

// Application

// Domain

export default class OperationsViewModel {
  i18n_namespace = 'components.allianz-dashboard.operations';

  operations_list = [
    {
      title: i18n.tc(`${this.i18n_namespace}.transfers`),
      content: i18n.tc(`${this.i18n_namespace}.coming_soon`),
      icon: 'mdi-fast-forward',
      modal: '',
    },
    {
      title: i18n.tc(`${this.i18n_namespace}.make_contributions`),
      content: i18n.tc(`${this.i18n_namespace}.make_contributions_description`),
      icon: 'mdi-plus',
      modal: 'show_deposits_dialog',
    },
    {
      title: i18n.tc(`${this.i18n_namespace}.request_withdrawals`),
      content: i18n.tc(`${this.i18n_namespace}.request_withdrawals_description`),
      icon: 'mdi-minus',
      modal: 'show_withdrawals_dialog',
    },
  ];

  modals: Record<string, boolean> = {
    show_withdrawals_dialog: false,
    show_deposits_dialog: false,
  }

  modal_calendly_open = false;

  showModal(modal: string) {
    this.modals[modal] = true;
  }

  showCalendlyModal = () => {
    this.modal_calendly_open = true;
  }
}

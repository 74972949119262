import TYPES from '@/types';

// Domain
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class AllianzDepositViewModel {
  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  i18n_namespace = 'components.allianz-dashboard.deposits';

  show_flexible_deposit_wizard = false;

  show_recurring_deposit_wizard = false;

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  depositOptionSelected = (deposit_type: number) => {
    this.show_recurring_deposit_wizard = deposit_type === 0;
    this.show_flexible_deposit_wizard = deposit_type === 1;
  }
}
